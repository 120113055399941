<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on">add user</v-btn>
    </template>-->
    <v-card>
      <v-card-title>
        <span class="headline">Change Password</span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-alert v-if="errorMsg" type="error">
            {{ errorMsg }}
          </v-alert>
          <v-form ref="changePasswordForm" v-model="valid" @submit.prevent>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="user.oldPassword"
                  label="Old Password"
                  required
                  type="password"
                  autocomplete="off"
                  :disabled="$apollo.loading"
                  :rules="requiredRule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.newPassword"
                  label="New Password"
                  required
                  type="password"
                  autocomplete="off"
                  :disabled="$apollo.loading"
                  :rules="passwordRule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.repeatPassword"
                  label="Repeat New Password"
                  required
                  type="password"
                  autocomplete="off"
                  :disabled="$apollo.loading"
                  :rules="passwordMatchRule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-3"
          text
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CHANGE_PASSWORD from '@/graphql/PasswordChange.gql'

export default {
  name: 'ChangePasswordDialog',
  props: {
    open: Boolean
  },
  data: () => ({
    errorMsg: null,
    user: {
      oldPassword: null,
      newPassword: null,
      repeatPassword: null
    },
    valid: true,
    passwordRule: [
      v => !!v || 'Password is required',
      v =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          v
        ) ||
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@$!%*#?&)'
    ]
  }),
  computed: {
    requiredRule() {
      const rules = []

      const requiredRule = v => !!v || `Field is required`

      rules.push(requiredRule)

      // No spaces allowed
      const noSpacesRule = v => (v || '').indexOf(' ') < 0 || `No Spaces allowd`

      rules.push(noSpacesRule)

      return rules
    },
    passwordMatchRule() {
      const rules = []

      const requiredRule = v => !!v || `Field is required`

      rules.push(requiredRule)

      if (!this.user.newPassord) {
        const rule = v =>
          (!!v && v) === this.user.newPassword || 'Passwords do not match'

        rules.push(rule)
      }

      return rules
    },
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        return val
      }
    }
  },
  apollo: {},
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      this.errorMsg = null
      this.$refs.changePasswordForm.reset()
      this.$emit('close-change-password-dialog', success)
    },

    save() {
      this.errorMsg = null
      // validate
      if (this.$refs.changePasswordForm.validate()) {
        let item = {
          oldPassword: this.user.oldPassword,
          newPassword: this.user.newPassword
        }

        this.$apollo
          .mutate({
            mutation: CHANGE_PASSWORD,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close(true)
          })
          .catch(err => {
            this.errorMsg = err.graphQLErrors[0].message
          })
      }
    }
  }
}
</script>

<style></style>
