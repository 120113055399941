<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
        <profile-update-dialog
          :open="dialog"
          :edited-item="editedItem"
          @close-update-profile-dialog="close"
        ></profile-update-dialog>
        <change-password-dialog
          :open="passwordDialog"
          @close-change-password-dialog="closePasswordDialog"
        ></change-password-dialog>
        <v-row align="center" justify="center">
          <v-col xs="12" sm="8" md="7" lg="6" xl="5">
            <v-row>
              <v-col cols="12" class="text-center">
                <h1 class="title">Personal info</h1>
                <h4 class="subtitle-1">
                  Basic info, such as your name and photo, that you use on
                  Clebbr services
                </h4>
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col cols="12">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title>{{
                    user.firstName + ' ' + user.lastName
                  }}</v-card-title>
                  <v-card-text>{{ user.email }}</v-card-text>
                  <v-card-text>
                    <v-chip v-if="user.userRole === 'admin'" label x-small>{{
                      user.userRole
                    }}</v-chip>
                    <div>
                      <span
                        v-if="user.status === 'active'"
                        class="secondary--text"
                        >{{ user.status }}</span
                      >

                      <span
                        v-else-if="user.status === 'suspended'"
                        class="error--text"
                        >{{ user.status }}</span
                      >
                      <span v-else class="secondary--text">{{
                        user.status
                      }}</span>
                    </div>
                    <div>
                      Last sign in:
                      <span v-if="user.lastLogin">{{
                        user.lastLogin | moment('DD/MM/YYYY @ HH:mm')
                      }}</span>
                      <span v-else>Never</span>
                    </div>
                    <div>
                      Created: {{ user.createdAt | moment('d MMM YYYY') }}
                    </div>
                    <v-divider class="my-4"></v-divider>
                    <div>Department</div>
                    <div class="secondary--text">
                      {{ user.department.name }}
                    </div>
                    <div class="mt-3">Reports to</div>
                    <div class="secondary--text">
                      {{
                        user.reportsTo.firstName + ' ' + user.reportsTo.lastName
                      }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="grey"
                      rounded
                      outlined
                      text
                      small
                      @click="editItem()"
                    >
                      <!-- <v-icon left>mdi-pencil-outline</v-icon> -->edit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col cols="12">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title>Contact Info</v-card-title>
                  <v-card-text>
                    <div>Email</div>
                    <div>{{ user.email }}</div>
                    <v-divider class="my-4"></v-divider>
                    <div>Phone</div>
                    <div>{{ user.phone }}</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="grey"
                      rounded
                      outlined
                      text
                      small
                      @click="editItem()"
                    >
                      edit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col cols="12">
                <v-card v-if="!$apollo.loading" flat class="mx-auto">
                  <v-card-title>Security</v-card-title>
                  <v-card-text>
                    <v-divider class="my-4"></v-divider>
                    <div>Password</div>
                    <!-- <div class="secondary--text">
                      Last changed
                      <span
                        v-if="user.lastLogin"
                      >{{ user.lastLogin | moment("calendar") }}</span>
                    </div>-->
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="grey"
                      rounded
                      outlined
                      text
                      small
                      @click="passwordDialog = true"
                    >
                      change password
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import GET_ME from '@/graphql/GetMe.gql'
import ProfileUpdateDialog from '@/modules/accounts/components/core/ProfileUpdateDialog.vue'
import ChangePasswordDialog from '@/modules/accounts/components/core/ChangePasswordDialog.vue'

export default {
  name: 'MyAccount',
  components: {
    ProfileUpdateDialog,
    ChangePasswordDialog
  },
  data: () => ({
    passwordDialog: false,
    dialog: false,
    defaultItem: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userRole: 'user',
      isAdmin: false,
      departmentID: '',
      department: {
        id: '',
        name: ''
      },
      status: 'active'
    },
    editedItem: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      userRole: 'user',
      isAdmin: false,
      departmentID: '',
      department: {
        id: '',
        name: ''
      },
      status: 'active'
    }
  }),
  computed: {
    user() {
      return this.getMe
    }
  },
  apollo: {
    getMe: GET_ME
  },
  methods: {
    close(success) {
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Changes Saved'
        })
      }
    },

    closePasswordDialog(success) {
      this.passwordDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Password Changed'
        })
      }
    },

    editItem() {
      this.editedItem = this._.cloneDeep(this.user)
      this.dialog = true
    }
  }
}
</script>

<style></style>
