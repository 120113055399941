<template>
  <div style="height: 100%;">
    <main-navigation nav-drawar-color="primary">
      <template v-slot:nav-items>
        <v-list-item
          :to="{ name: 'MyAccount' }"
          class="menu-item"
          link
          color="#fff"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>MyAccount</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </main-navigation>

    <div class="app-layout">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainNavigation from '@/components/core/MainNavigation.vue'

export default {
  name: 'AccountsLayout',
  components: {
    MainNavigation
  },
  data: () => ({
    drawer: true,
    loggedInUser: {
      firstName: 'Stan',
      lastName: 'Nok'
    }
  }),
  apollo: {},
  computed: {},
  created() {
    this.setTheme()
  },
  methods: {
    setTheme() {
      this.$vuetify.theme.themes.light.primary = '#442980' // #1976D2'
      this.$vuetify.theme.themes.light.secondary = '#424242'
      this.$vuetify.theme.themes.light.accent = '#82B1FF'
      this.$vuetify.theme.themes.light.error = '#FF5252'
      this.$vuetify.theme.themes.light.info = '#2196F3'
      this.$vuetify.theme.themes.light.success = '#4CAF50'
      this.$vuetify.theme.themes.light.warning = '#FFC107'
    }
  }
}
</script>

<style></style>
