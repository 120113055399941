<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed v-on="on">add user</v-btn>
    </template>-->
    <v-card>
      <v-card-title>
        <span class="headline">Edit Profile</span>
      </v-card-title>

      <v-card-text v-if="user">
        <v-container grid-list-md>
          <v-form
            ref="userForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  v-model="user.firstName"
                  :rules="nameRules"
                  label="First Name"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="user.lastName"
                  :rules="nameRules"
                  label="Last Name"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="user.phone"
                  label="Phone Number"
                  required
                  :disabled="$apollo.loading"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary lighten-3"
          text
          :disabled="$apollo.loading"
          @click="close(false)"
          >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          :loading="$apollo.loading"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ME_UPDATE from '@/graphql/MeUpdate.gql'

export default {
  name: 'ProfileUpdateDialog',
  props: {
    editedItem: { type: Object, default: null },
    editMode: Boolean,
    open: Boolean
  },
  data: () => ({
    valid: true,
    emailRules: [
      v => !!v || 'E-mail is required',
      v =>
        /@dailypaperclothing.com\s*$/.test(v) ||
        'You can only use your DP email address'
    ],
    nameRules: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredRule: [
      v => !!v.id || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ]
  }),
  computed: {
    dialog: {
      get() {
        return this.open
      },
      set(val) {
        // if (val) {
        //   return true
        // }
        return val
      }
    },
    user: {
      get() {
        return this.editedItem
      },
      set() {
        this._.cloneDeep(this.editedItem)
      }
    }
  },
  apollo: {},
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  methods: {
    close(success) {
      // if (!this.editMode) {
      //   this.$refs.userForm.reset()
      // }

      this.$emit('close-update-profile-dialog', success)
    },

    save() {
      // Edit item
      let item = {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        phone: this.user.phone,
        userRole: this.user.userRole,
        reportsToID: this.user.reportsTo.id,
        isAdmin: this.user.isAdmin,
        departmentID: this.user.department.id,
        status: this.user.status
      }
      // validate
      if (this.$refs.userForm.validate()) {
        this.$apollo
          .mutate({
            mutation: ME_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.close(true)
          })
      }
    }
  }
}
</script>

<style></style>
